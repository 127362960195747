import React, { useEffect } from "react";
import DiscountPage from "../pages/discountPage";
import { tgIcon, vkIcon } from "../../images";
import Container from "../hoc/Container";

function NintendoDiscount() {

  return (
    <Container>
      <div>
        <div className="content-block">
          <div className="head-1">Скоро тут будут лучшие предложения Nintendo</div>
          <div>
            А пока можете посмотреть скидки здесь:<br/>
            <a style={{color: "rgb(0, 0, 238)"}} href="https://psprices.com/region-us/collection/most-wanted-deals?platform=Switch">Nintendo США</a><br/>
            <a style={{color: "rgb(0, 0, 238)"}} href="https://psprices.com/region-pl/collection/most-wanted-deals?platform=Switch">Nintendo Польша</a>
          </div>
        </div>
      </div>
    </Container>
  );
  // useEffect(() => {
  //   window.ym(93997310, "hit", "/nintendo-discount", {
  //     params: {
  //       title: "Скидки Nintendo",
  //       referer: "https://trendy-eng-shop.ru/",
  //     },
  //   });
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);
  // const platform = [{ value: "SWITCH", label: "Nintendo eShop" }];
  // const region = [
  //   { value: "US", label: "USA" },
  //   { value: "PL", label: "Poland" },
  // ];
  // const button = (
  //   <div
  //     onClick={() => window.ym(93997310, "reachGoal", "tgClickNintendo")}
  //     className="flex justify-center md:justify-between"
  //   >
  //     <a
  //       href={"https://t.me/trendy_eng_shop_bot?start=ref-001"}
  //       className="mb-3 flex w-40 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:mb-0"
  //       target="_blank"
  //       rel="noreferrer"
  //     >
  //       Купить в <img src={tgIcon} alt="TG" className=" ml-2" />
  //     </a>
  //     <a
  //       href={"https://vk.com/trendy_eng_shop"}
  //       className="mb-3 ml-2 flex w-40 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:mx-2 md:mb-0"
  //       target="_blank"
  //       rel="noreferrer"
  //     >
  //       Купить в <img src={vkIcon} alt="TG" className=" ml-2 scale-125" />
  //     </a>
  //   </div>
  // );
  // return <DiscountPage shop={platform} region={region} button={button} />;
}

export default NintendoDiscount;
