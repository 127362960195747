import React, { useEffect } from "react";
import DiscountPage from "../pages/discountPage";
import { tgIcon } from "../../images";
import Container from "../hoc/Container";

function PlayStationDiscount() {
  return (
    <Container>
      <div className="content-block">
        <div className="head-1">Скоро тут будут лучшие предложения PlayStation</div>
        <div>
          А пока можете посмотреть скидки здесь:<br/>
          <a style={{color: "rgb(0, 0, 238)"}} href="https://psprices.com/region-pl/collection/most-wanted-deals">PSN Польша</a><br/>
          <a style={{color: "rgb(0, 0, 238)"}} href="https://psprices.com/region-in/collection/most-wanted-deals">PSN Индия</a>
        </div>
      </div>
    </Container>
  );
  //
  // useEffect(() => {
  //   window.ym(93997310, "hit", "/playstation-discount", {
  //     params: {
  //       title: "Скидки PlayStation",
  //       referer: "https://trendy-eng-shop.ru/",
  //     },
  //   });
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);
  // const platform = [
  //   { value: "PS5", label: "PlayStation 5" },
  //   { value: "PS4", label: "PlayStation 4" },
  // ];
  // const region = [{ value: "PL", label: "Poland" }];
  // const button = (
  //   <div
  //     onClick={() => window.ym(93997310, "reachGoal", "tgClickPlayStation")}
  //     className="flex items-center justify-center"
  //   >
  //     <a
  //       href={"https://t.me/trendy_psn_bot?start=ref-001"}
  //       className=" mb-3 flex w-40 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:mb-0"
  //       target="_blank"
  //       rel="noreferrer"
  //     >
  //       Купить в <img src={tgIcon} alt="TG" className=" ml-2" />
  //     </a>
  //   </div>
  // );
  // return <DiscountPage shop={platform} region={region} button={button} />;
}

export default PlayStationDiscount;
