import React from "react";
import Container from "../hoc/Container";

function SteamDiscount() {
  return (
    <Container>
      <div className="content-block">
        <div className="head-1">Скоро тут будут лучшие предложения Steam</div>
        <div>
          А пока можете посмотреть скидки здесь:<br/>
          <a style={{color: "rgb(0, 0, 238)"}} href="https://store.steampowered.com/specials#tab=TopSellers">Steam</a><br/>
        </div>
      </div>
    </Container>
  );
}

export default SteamDiscount;
